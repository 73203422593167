/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createArInventory = /* GraphQL */ `
  mutation CreateArInventory(
    $input: CreateArInventoryInput!
    $condition: ModelArInventoryConditionInput
  ) {
    createArInventory(input: $input, condition: $condition) {
      id
      Agency_Price_Group
      Product_Code
      Supplier
      Product_Name_j
      Quantity
      Capacity_Display
      Catarog_Price
      Wholes_Rate
      Wholes_Price
      Delivery_Term
      Storage_Temp
      Capacity_Unit
      Capacity_Value
      Product_Name_e
      Plant_Name
      Wharehouse_Name
      Category1
      Category2
      Product_Group1
      Product_Group2
      Supplier_LotNo
      Expiration_date
      lastupdate
      updatedAt
      createdAt
    }
  }
`;
export const updateArInventory = /* GraphQL */ `
  mutation UpdateArInventory(
    $input: UpdateArInventoryInput!
    $condition: ModelArInventoryConditionInput
  ) {
    updateArInventory(input: $input, condition: $condition) {
      id
      Agency_Price_Group
      Product_Code
      Supplier
      Product_Name_j
      Quantity
      Capacity_Display
      Catarog_Price
      Wholes_Rate
      Wholes_Price
      Delivery_Term
      Storage_Temp
      Capacity_Unit
      Capacity_Value
      Product_Name_e
      Plant_Name
      Wharehouse_Name
      Category1
      Category2
      Product_Group1
      Product_Group2
      Supplier_LotNo
      Expiration_date
      lastupdate
      updatedAt
      createdAt
    }
  }
`;
export const deleteArInventory = /* GraphQL */ `
  mutation DeleteArInventory(
    $input: DeleteArInventoryInput!
    $condition: ModelArInventoryConditionInput
  ) {
    deleteArInventory(input: $input, condition: $condition) {
      id
      Agency_Price_Group
      Product_Code
      Supplier
      Product_Name_j
      Quantity
      Capacity_Display
      Catarog_Price
      Wholes_Rate
      Wholes_Price
      Delivery_Term
      Storage_Temp
      Capacity_Unit
      Capacity_Value
      Product_Name_e
      Plant_Name
      Wharehouse_Name
      Category1
      Category2
      Product_Group1
      Product_Group2
      Supplier_LotNo
      Expiration_date
      lastupdate
      updatedAt
      createdAt
    }
  }
`;
export const createArAgency = /* GraphQL */ `
  mutation CreateArAgency(
    $input: CreateArAgencyInput!
    $condition: ModelArAgencyConditionInput
  ) {
    createArAgency(input: $input, condition: $condition) {
      Agency_id
      SubId
      Agency_Price_Group
      Company_Name
      eMail
      updatedAt
      createdAt
    }
  }
`;
export const updateArAgency = /* GraphQL */ `
  mutation UpdateArAgency(
    $input: UpdateArAgencyInput!
    $condition: ModelArAgencyConditionInput
  ) {
    updateArAgency(input: $input, condition: $condition) {
      Agency_id
      SubId
      Agency_Price_Group
      Company_Name
      eMail
      updatedAt
      createdAt
    }
  }
`;
export const deleteArAgency = /* GraphQL */ `
  mutation DeleteArAgency(
    $input: DeleteArAgencyInput!
    $condition: ModelArAgencyConditionInput
  ) {
    deleteArAgency(input: $input, condition: $condition) {
      Agency_id
      SubId
      Agency_Price_Group
      Company_Name
      eMail
      updatedAt
      createdAt
    }
  }
`;
// export const createArCampaign = /* GraphQL */ `
//   mutation CreateArCampaign(
//     $input: CreateArCampaignInput!
//     $condition: ModelArCampaignConditionInput
//   ) {
//     createArCampaign(input: $input, condition: $condition) {
//       id
//       Title
//       TermFrom
//       TermTo
//       Text
//       updatedAt
//       createdAt
//     }
//   }
// `;
export const createArCampaign = /* GraphQL */ `
  mutation CreateArCampaign(
    $input: CreateArCampaignInput!
    $condition: ModelArCampaignConditionInput
  ) {
    createArCampaign(input: $input, condition: $condition) {
      id
      Title
      Term_From
      Term_To
      Wholes_Rate_Condision
      Text
      Discription
      Notification
      Remarks
      filePDF
      PDFurl
      updatedAt
      createdAt
    }
  }
`;
export const deleteArCampaign = /* GraphQL */ `
  mutation DeleteArCampaign(
    $input: DeleteArCampaignInput!
    $condition: ModelArCampaignConditionInput
  ) {
    deleteArCampaign(input: $input, condition: $condition) {
      id
      Title
      Term_From
      Term_To
      Wholes_Rate_Condision
      Text
      Discription
      Notification
      Remarks
      filePDF
      PDFurl
      updatedAt
      createdAt
    }
  }
`;

// export const updateArCampaign = /* GraphQL */ `
//   mutation UpdateArCampaign(
//     $input: UpdateArCampaignInput!
//     $condition: ModelArCampaignConditionInput
//   ) {
//     updateArCampaign(input: $input, condition: $condition) {
//       id
//       Title
//       TermFrom
//       TermTo
//       Text
//       updatedAt
//       createdAt
//     }
//   }
// `;


export const updateArCampaign = /* GraphQL */ `
  mutation UpdateArCampaign(
    $input: UpdateArCampaignInput!
    $condition: ModelArCampaignConditionInput
  ) {
    updateArCampaign(input: $input, condition: $condition) {
      id
      Title
      Term_From
      Term_To
      Wholes_Rate_Condision
      Text
      Discription
      Notification
      Remarks
      filePDF
      PDFurl
      updatedAt
      createdAt
    }
  }
`;