/* ar-brown App (C) 2021 arbrown.co.jp All rights reserved.
 *******************************************************************************
 * Program ID:  Agent.js
 * $Revision:   Ver1.0
 * $Modtime:    $
 *******************************************************************************
 * Author      (作成／更新年月日、担当者)
 *  2021/08/01 Rayoo)li : 新規作成
 *
 * Summary Agent画面
 *
 * Instructions / Preconditions
 *  なし
 *
 *******************************************************************************
 */
// pages
import Meta from '../components/Meta'
import userAuth from '../components/userAuth'
import LoginAgent from '../components/LoginAgent'
import React, { useEffect, useReducer } from 'react';
/*  GraphQL API Acess */
import API, { graphqlOperation } from '@aws-amplify/api';
import { customerByeMail } from '../graphql/queries';
/*  AWS標準設定 with Login Auth. */
import Amplify, { Auth } from 'aws-amplify';
import awsconfig from '../aws-exports';
import { Container } from 'react-bootstrap'
Amplify.configure(awsconfig);


/* Login時 下記取得するための初期化処理
    1) Auth.currentSession() から、Cogniteのユーザグループ情報を取得
    2) Auth.currentUserInfo() から、Agent情報を取得して、agencyGroupをSet
*/
const eMailQUERY = 'eMailQUERY';
const initialAgentState = { Agent: [] };
const reducer = (state, action) => {
  switch (action.type) {
    case eMailQUERY:
      return { ...state, Agent: action.Agent };
    default:
      return state;
  }
};

export const Agent = () => {
  const [agentGroupID, getAgent] = useReducer(reducer, initialAgentState);
  useEffect(() => {
    // 1) Auth.currentSession() から、Cogniteのユーザグループ情報を取得
    async function getLoginGroup() {
      // get the Login User Group from the top of the cognito groups list
      const user = await Auth.currentUserInfo();
      const agencyPriceGroup = await API.graphql(graphqlOperation(customerByeMail, { Agency_Email: user.attributes.email }));
      const agentinfo = agencyPriceGroup.data.customerByeMail['items'];
      const userAuths = await userAuth();
      if (userAuths === '1') {
        if (agentinfo.length > 0) {
          getAgent({ type: eMailQUERY, Agent: agentinfo });
        }
      } else {
        let items = { Agency_id: "‐", SubId: "‐", Company_Name: "‐", Agency_Name: "管理者" }
        let itemALL = []
        itemALL.push(items);
        getAgent({ type: eMailQUERY, Agent: itemALL });
      }
    }
    getLoginGroup();
  }, []);

  const pageTitle = '代理店情報｜ARB-SIP（在庫・価格照会システム）'

  return (
    <Container style={{ backgroundColor: '', 'minWidth': '85vw', 'minHeight': '74vh' }}>
      <div>
        <Meta title={pageTitle} />
        {agentGroupID.Agent.length > 0 ?
          agentGroupID.Agent.map((email) =>
            <LoginAgent
              key={email.Agency_Email}
              Agency_Name={email.Agency_Name}
              CompanyName={email.Company_Name}
              AgencyID={email.Agency_id}
              SubID={email.SubId}
            // AgencyPG={email.Agency_Price_Group}
            // Mail={email.Agency_Group}
            />
          ) :
          <p>loading...!</p>
        }
      </div>
    </Container>
  )
}

export default Agent